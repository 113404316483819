<template>
 <div>
  <b-card bg-variant="light">
    <product-item-select @selectedItems="setId" />
    <b-form-group 
      label-cols-lg="3"
      label="Product Link"
      label-size="lg"
      label-class="font-weight-bold pt-0"
      class="mb-0"
    >
      <b-form-group
        label="Link Type:"
        label-cols-sm="3"
        label-align-sm="right"
        class="mb-0"
        v-slot="{ ariaDescribedby }"
      >
        <b-form-radio-group
            class="pt-2"
            v-model="product_item_link.link_type"
            :options="['purchase', 'value', 'grade']"
            :aria-describedby="ariaDescribedby"
        ></b-form-radio-group>
      </b-form-group>
      <b-form-group
        label="URL Link:"
        label-for="nested-url-link"
        label-cols-sm="3"
        label-align-sm="right"
      >
        <b-form-input id="nested-url-link"
          v-model="product_item_link.url_link"
        ></b-form-input>
      </b-form-group>

    </b-form-group>

    <b-button block variant="primary" @click="callApi">Add Product Link</b-button>
  </b-card>
 </div>
</template>

<script>
import {http_v1} from '@/axios'
import ProductItemSelect from './ProductItemSelect.vue'

export default {
  components: {
    ProductItemSelect
  },
  data () {
    return {
      product_item_link:{
        product_item_id: '',
        link_type: 'purchase',
        url_link: '',
      },
    }
  },
  methods: {
    setId(items) {
      this.product_item_link.product_item_id = items[0].id
    },
    callApi () {
      http_v1.post (
        'api/admin/v1/product_item_links', {
          ...this.product_item_link
        }
      ).then(() => {
        // this.$router.push( {name: 'ProductItemLinkIndex'})
        this.$router.go(-1)
      }).catch(error => {
        if( error.response && error.response.data) {
          var message = error.response.data.message || JSON.stringify(error.response.data)
          if (error.response.status === 500 ) message = "500 Server Error"
          this.$bvToast.toast(message, {
            title: 'Error',
            autoHideDelay: 5000,
            appendToast: true
          })
        }
      })
    },
  },
}
</script>